import React from 'react'

export default function KongLogo() {
  return (
    <svg width="65" viewBox="0 0 83 28" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.961 27h-7.47L6.892 15.18 7.075 27H.152V1.367h6.923l-.183 10.99 4.084-10.99h7.305l-5.529 12.368L18.962 27zm20.055-12.733c0 3.907-.89 7.083-2.673 9.529-1.77 2.435-4.078 3.652-6.923 3.652-2.523 0-4.687-.935-6.49-2.805-2.27-2.369-3.404-5.922-3.404-10.659 0-4.537 1.184-8.007 3.553-10.409 1.76-1.782 3.846-2.673 6.258-2.673 2.955 0 5.307 1.207 7.056 3.62 1.749 2.412 2.623 5.66 2.623 9.745zm-7.188.05c0-4.638-.82-6.957-2.457-6.957-.82 0-1.472.61-1.96 1.827-.475 1.128-.713 2.789-.713 4.98 0 2.158.224 3.832.672 5.022.448 1.19 1.07 1.785 1.868 1.785.819 0 1.45-.587 1.892-1.76.465-1.173.698-2.806.698-4.898zM41.97 27V1.367h6.608l3.47 8.998.639 1.826.375 1.109c.132.395.27.817.413 1.265l.714 2.258-.234-2.117c-.143-1.34-.259-2.539-.347-3.594l-.072-.919a43.68 43.68 0 01-.127-3.065v-5.76h6.607V27H53.41l-3.487-9.43c-.365-1.007-.694-1.95-.987-2.83a51.91 51.91 0 01-.772-2.515l.13 1.726c.08 1.1.14 2.096.185 2.988l.044.974c.03.785.049 1.505.054 2.16L48.58 27H41.97zm30.846-14.825h9.662v1.062c0 4.937-.736 8.49-2.208 10.659-1.605 2.368-3.979 3.552-7.122 3.552-3.088 0-5.512-1.162-7.272-3.486-1.748-2.346-2.623-5.567-2.623-9.662 0-4.15.88-7.421 2.64-9.812 1.76-2.39 4.167-3.586 7.222-3.586 4.05 0 6.989 2.114 8.815 6.342l-6.707 2.457c-.1-.808-.33-1.447-.689-1.917-.36-.47-.81-.706-1.353-.706-1.904 0-2.855 2.618-2.855 7.853 0 2.224.21 3.84.63 4.847.421 1.007 1.09 1.511 2.01 1.511.365 0 .697-.088.995-.266.3-.177.551-.426.756-.747.205-.32.365-.71.481-1.17.116-.46.175-.971.175-1.536h-2.557v-5.395z"
        fill="#FFF"
        fillRule="nonzero"
      ></path>
    </svg>
  )
}
