import React from 'react'

export default function ExternalIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <rect stroke="#0C0C0C" width="15" height="15" x=".5" y=".5" rx="3.5" />
      <path stroke="#0C0C0C" strokeLinecap="round" strokeLinejoin="round" d="m8 8-4 4M8 12V8H4" />
    </svg>
  )
}
