import React from 'react'

export default function GlobeIcon() {
  return (
    <svg
      className="grid-item-social-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <circle cx="8" cy="8" r="7.5" stroke="#0C0C0C" />
      <path
        stroke="#0C0C0C"
        d="M11.5 8c0 2.148-.437 4.069-1.119 5.433C9.684 14.828 8.813 15.5 8 15.5c-.813 0-1.684-.672-2.381-2.067C4.936 12.07 4.5 10.148 4.5 8c0-2.148.436-4.069 1.119-5.433C6.316 1.172 7.187.5 8 .5c.813 0 1.684.672 2.381 2.067C11.063 3.93 11.5 5.852 11.5 8ZM1 5.5h14M1 10.5h14M8 16V0"
      />
    </svg>
  )
}
