import React from 'react'

export default function TwitterIcon() {
  return (
    <svg
      className="grid-item-social-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      fill="none"
      viewBox="0 0 20 16"
    >
      <path
        fill="#0C0C0C"
        d="M6.195 16c7.429 0 11.493-6.156 11.493-11.493 0-.173-.004-.35-.011-.523a8.205 8.205 0 0 0 2.015-2.092 7.966 7.966 0 0 1-2.32.635A4.062 4.062 0 0 0 19.15.293c-.793.47-1.66.801-2.564.98a4.045 4.045 0 0 0-6.887 3.684A11.474 11.474 0 0 1 1.373.739a4.044 4.044 0 0 0 1.25 5.39 4.045 4.045 0 0 1-1.83-.503v.05a4.038 4.038 0 0 0 3.24 3.961 4.016 4.016 0 0 1-1.822.07 4.048 4.048 0 0 0 3.772 2.807A8.103 8.103 0 0 1 0 14.185 11.462 11.462 0 0 0 6.195 16Z"
      />
    </svg>
  )
}
