import React from 'react'

export default function Ers() {
  return (
    <svg width="35" height="32" viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="ers-mask" fill="currentColor">
        <path d="M23.0863 1.06627e-07C25.3442 1.28166e-07 27.4306 1.20458 28.5595 3.15998L34.1483 12.84C35.2772 14.7954 35.2772 17.2046 34.1483 19.16L28.5595 28.84C27.4306 30.7954 25.3442 32 23.0863 32H11.9087C9.65083 32 7.56444 30.7954 6.43548 28.84L0.846715 19.16C-0.282238 17.2046 -0.282239 14.7954 0.846714 12.84L6.43548 3.15998C7.56444 1.20458 9.65083 -2.1539e-08 11.9087 0L23.0863 1.06627e-07Z"></path>
      </mask>
      <path
        d="M6.43548 3.15998L7.30151 3.65998V3.65998L6.43548 3.15998ZM11.9087 0V1V0ZM0.846715 19.16L1.71274 18.66L0.846715 19.16ZM0.846714 12.84L-0.0193111 12.34H-0.0193111L0.846714 12.84ZM11.9087 32V31V32ZM6.43548 28.84L5.56946 29.34L6.43548 28.84ZM28.5595 28.84L29.4256 29.34L28.5595 28.84ZM23.0863 32V33H23.0863L23.0863 32ZM34.1483 12.84L33.2823 13.34L34.1483 12.84ZM34.1483 19.16L33.2823 18.66L34.1483 19.16ZM28.5595 3.15998L29.4256 2.65998L28.5595 3.15998ZM23.0863 1.06627e-07L23.0863 -1L23.0863 1.06627e-07ZM27.6935 3.65998L33.2823 13.34L35.0143 12.34L29.4256 2.65998L27.6935 3.65998ZM33.2823 18.66L27.6935 28.34L29.4256 29.34L35.0143 19.66L33.2823 18.66ZM23.0863 31H11.9087V33H23.0863V31ZM7.30151 28.34L1.71274 18.66L-0.0193102 19.66L5.56946 29.34L7.30151 28.34ZM1.71274 13.34L7.30151 3.65998L5.56946 2.65998L-0.0193111 12.34L1.71274 13.34ZM11.9087 1L23.0863 1L23.0863 -1L11.9087 -1V1ZM7.30151 3.65998C8.25183 2.01398 10.0081 1 11.9087 1V-1C9.29356 -1 6.87704 0.395179 5.56946 2.65998L7.30151 3.65998ZM1.71274 18.66C0.76242 17.014 0.76242 14.986 1.71274 13.34L-0.0193111 12.34C-1.3269 14.6048 -1.3269 17.3952 -0.0193102 19.66L1.71274 18.66ZM11.9087 31C10.0081 31 8.25183 29.986 7.30151 28.34L5.56946 29.34C6.87704 31.6048 9.29357 33 11.9087 33V31ZM27.6935 28.34C26.7432 29.986 24.9869 31 23.0863 31L23.0863 33C25.7014 33 28.118 31.6048 29.4256 29.34L27.6935 28.34ZM33.2823 13.34C34.2326 14.986 34.2326 17.014 33.2823 18.66L35.0143 19.66C36.3219 17.3952 36.3219 14.6048 35.0143 12.34L33.2823 13.34ZM29.4256 2.65998C28.118 0.395177 25.7014 -1 23.0863 -1L23.0863 1C24.9869 1 26.7432 2.01398 27.6935 3.65998L29.4256 2.65998Z"
        fill="currentColor"
        mask="url(#ers-mask)"
      ></path>
      <path
        d="M13.6552 20V18.92H9.41924V16.22H12.9112V15.14H9.41924V12.68H13.5352V11.6H8.21924V20H13.6552Z"
        fill="currentColor"
      ></path>
      <path
        d="M19.0146 15.932C19.8666 15.596 20.3586 14.876 20.3586 13.856C20.3586 12.548 19.2426 11.6 17.7786 11.6H14.8626V20H16.0626V16.628H18.3906C18.8586 16.628 19.0386 16.808 19.0386 17.276V20H20.2386V17.084C20.2386 16.328 19.9026 15.932 19.0746 15.932H19.0146ZM16.0626 15.548V12.68H17.5026C18.5586 12.68 19.1586 13.172 19.1586 14.072C19.1586 14.996 18.4626 15.548 17.5026 15.548H16.0626Z"
        fill="currentColor"
      ></path>
      <path
        d="M24.1699 20.12C26.0539 20.12 27.1699 19.076 27.1699 17.648C27.1699 16.34 26.3779 15.62 24.9859 15.332L23.7739 15.08C22.9339 14.912 22.5619 14.576 22.5619 13.916C22.5619 13.16 23.2339 12.56 24.2539 12.56H24.3019C25.3339 12.56 26.0419 13.148 26.1019 14.144H27.3019C27.2659 12.572 26.1859 11.48 24.2899 11.48C22.4539 11.48 21.3619 12.632 21.3619 13.94C21.3619 15.176 22.0099 15.92 23.4259 16.22L24.7579 16.508C25.6219 16.688 25.9699 17.084 25.9699 17.72C25.9699 18.44 25.3939 19.04 24.2899 19.04H24.2299C22.9459 19.04 22.2019 18.488 22.1419 17.276H20.9419C20.9779 18.968 21.9859 20.12 24.1699 20.12Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}
