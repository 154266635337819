import React from 'react'
import Ers from '../svg/Ers'

export default function Footer() {
  return (
    <footer className="footer">
      <Ers />
      <p>Verified by ERS</p>
    </footer>
  )
}
