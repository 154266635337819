import React from 'react'
import { ARWEAVE_NODE } from '../constants'
import { getChainData } from '../helpers/get-chain-data'
import truncateAddress from '../helpers/truncate-address'
import applicationStore from '../stores/applicationStore'
import Card from './Card'
import CardPadding from './CardPadding'
import ContentDetail from './ContentDetail'
import Divider from './Divider'
import { marked } from 'marked'
import ExternalIcon from './ExternalIcon'
import GlobeIcon from './GlobeIcon'
import TwitterIcon from './TwitterIcon'

export default function Content() {
  const device = applicationStore((s) => s.device)
  const keys = applicationStore((s) => s.deviceKeys)
  const meta = JSON.parse(device.device_token_metadata)
  const chainId = applicationStore((s) => s.walletChainId)
  const explorer = getChainData(chainId).explorer
  const isVideo = device.content_type.indexOf('video') > -1

  console.log(meta)

  return (
    <>
      {isVideo ? (
        <video className="cover" autoPlay loop playsInline muted>
          <source src={`${ARWEAVE_NODE}/${device.node_id}`} />
        </video>
      ) : (
        <img className="cover" src={`${ARWEAVE_NODE}/${device.node_id}`} />
      )}

      <div className="grid">
        <div className="grid-item">
          <h1>{meta.title}</h1>
          <p className="grid-item-meta">
            {meta.ensDomain} | Citizen #{meta.citizenId}
          </p>
          <div className="grid-item-description" dangerouslySetInnerHTML={{ __html: marked(meta.description) }} />
        </div>

        {meta?.links.map((link, i) => {
          return (
            <div key={i} className="grid-item grid-item--1/2 grid-item--social">
              <a href={link.url}>
                {link.type === 'twitter' ? <TwitterIcon /> : <GlobeIcon />}
                <span className="grid-item-social-text">
                  {link.text}
                  <ExternalIcon />
                </span>
              </a>
            </div>
          )
        })}

        {meta?.claimables && (
          <div className="grid-item">
            <h2>POAP's to claim</h2>
            {meta?.claimables.map((claimable) => {
              return (
                <div className="claim-item">
                  <div className="claim-item-flex">
                    {/* <div className="claim-item-flex-image">
                    <img src="./assets/claim-preview.jpg" alt="Claim preview" />
                  </div> */}
                    <div className="claim-item-flex-text">
                      <h3>{claimable.title}</h3>
                      <p>{claimable.description}</p>
                    </div>
                  </div>
                  <a href={claimable.url} className="claim-item-button">
                    Claim
                  </a>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </>
  )
}
